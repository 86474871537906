import { Box, styled, Typography } from "@mui/material";
import ContentContainer from "../layout/ContentContainer";
import ActionButton from "../shared/ActionButton";
import { useNavigate } from "react-router-dom";

const JoinUsWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "600px",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  background: theme.palette.primary.light,
  WebkitClipPath: "polygon(0 0, 0 100%, 0% 100%, 100% 88%, 100% 0)",
  clipPath: "polygon(0 0, 0 100%, 0% 100%, 100% 88%, 100% 12%)",
  backgroundImage: `url(${
    process.env.PUBLIC_URL + "/images/join_us_safa.png"
  })`,
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "100%",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
  "& .leftBox": {
    maxWidth: "580px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  "& .MuiContainer-root": {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
}));

const JoinUsSection = ({
  isCareerPage = false,
}: {
  isCareerPage?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <JoinUsWrapper id="join-us">
      <ContentContainer>
        <Box className={"leftBox"}>
          <Typography variant={"h2"}>Join us</Typography>
          <Typography
            variant={"body2"}
            sx={{ pt: 2, pb: 5, lineHeight: "219%" }}
          >
            SAFA is proud of the diverse backgrounds and extensive expertise of
            the company's researchers. We are a fast growing company searching
            for individuals with an extensive background in cybersecurity
            research with an emphasis on vulnerability discovery and reverse
            engineering.
          </Typography>
          <ActionButton
            sx={{ display: isCareerPage ? "none" : "" }}
            text={"find out more"}
            color={"#58C9B3"}
            onClick={() => navigate("/career")}
          />
        </Box>
      </ContentContainer>
    </JoinUsWrapper>
  );
};
export default JoinUsSection;
