import HeroSection from "../shared/HeroSection";
import { styled } from "@mui/material";
import DataSection from "../sections/dataProtection/DataSection";
import TermsOfUseTxt from "../sections/dataProtection/TermsOfUseTxt";
import { useParams } from "react-router-dom";
import ImprintSection from "../sections/dataProtection/Imprint";
import { useEffect } from "react";
import ResponsibleDisclosurePolicySection from "../sections/dataProtection/ResponsibleDisclosurePolicy";

const DataWrapper = styled("div")(({ theme }) => ({
  width: "100%",
}));

const DATA_PROTECTION_URL = "data-protection";
const TERMS_OF_USE_URL = "terms-of-use";
const IMPRINT_URL = "imprint";
const RDP_URL = "responsible-disclosure-policy";

const DataProtection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line

  const { subpage } = useParams();

  const getHeadline = (): string => {
    if (subpage === DATA_PROTECTION_URL) {
      return "Data Protection";
    } else if (subpage === TERMS_OF_USE_URL) {
      return "Terms of Use";
    } else if (subpage === IMPRINT_URL) {
      return "Imprint";
    } else if (subpage === RDP_URL) {
      return "Responsible Disclosure Policy";
    }
    return "";
  };

  return (
    <DataWrapper>
      <HeroSection headline={getHeadline()} />
      {subpage === DATA_PROTECTION_URL && <DataSection />}
      {subpage === TERMS_OF_USE_URL && <TermsOfUseTxt />}
      {subpage === IMPRINT_URL && <ImprintSection />}
      {subpage === RDP_URL && <ResponsibleDisclosurePolicySection />}
    </DataWrapper>
  );
};

export default DataProtection;
