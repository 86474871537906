import { ThemeOptions } from "@mui/material/styles";
import { createBreakpoints } from "@mui/system";

const PRIMARY_FONT_FAMILY: string = "Rubik";
const PRIMARY_FONT_COLOR = "#FFFFFF";
const SECONDARY_FONT_COLOR = "#000000";
const LIGHTGREEN_FONT_COLOR = "#58C9B3";

const breakpoints = createBreakpoints({});

export const safaTheme: ThemeOptions = {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          height: "63px",
          fontSize: "16px",
          fontWeight: 500,
          fontFamily: PRIMARY_FONT_FAMILY,
          lineHeight: "35px",
          letterSpacing: "0.155em",
          textTransform: "none",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: PRIMARY_FONT_FAMILY,
        },
      },
    },
  },
  palette: {
    background: {
      paper: "#FFFFFF",
      default: "#031433",
    },
    primary: {
      main: "#031433",
      light: "#58C9B3",
      dark: "#276572",
      contrastText: "#48474C",
    },
  },
  typography: {
    fontWeightBold: 700,
    fontWeightMedium: 500,
    h1: {
      color: PRIMARY_FONT_COLOR,
      fontWeight: 400,
      fontSize: "48px",
      lineHeight: "130.5%",
      fontStyle: "normal",
      [breakpoints.down("md")]: {
        fontWeight: 300,
        fontSize: "36px",
      },
    },
    h2: {
      color: SECONDARY_FONT_COLOR,
      fontWeight: 400,
      fontSize: "38px",
      lineHeight: "130.5%",
      [breakpoints.down("md")]: {
        fontWeight: 400,
        fontSize: "32px",
      },
    },
    h3: {
      color: PRIMARY_FONT_COLOR,
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "139.5%",
      [breakpoints.down("md")]: {
        fontWeight: 600,
        fontSize: "20px",
      },
    },
    h4: {
      color: LIGHTGREEN_FONT_COLOR,
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "160.5%",
      [breakpoints.down("md")]: {
        fontWeight: 500,
        fontSize: "20px",
      },
    },
    h6: {
      color: SECONDARY_FONT_COLOR,
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "180%",
    },
    body1: {
      color: SECONDARY_FONT_COLOR,
      fontWeight: 300,
      fontStyle: "normal",
      fontSize: "24px",
      lineHeight: "180%",
      letterSpacing: "0.1em",
      [breakpoints.down("md")]: {
        fontWeight: 300,
        fontSize: "20px",
      },
      [breakpoints.down("sm")]: {
        fontWeight: 300,
        fontSize: "18px",
        letterSpacing: "0",
      },
    },
    body2: {
      color: SECONDARY_FONT_COLOR,
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "180%",
      [breakpoints.down("lg")]: {
        fontSize: "14px",
      },
    },
    subtitle1: {
      color: LIGHTGREEN_FONT_COLOR,
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "139.5%",
      [breakpoints.down("sm")]: {
        lineHeight: "200%",
      },
    },
    subtitle2: {
      color: SECONDARY_FONT_COLOR,
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "219%",
      [breakpoints.down("lg")]: {
        fontSize: "20px",
      },
      [breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
    button: {
      color: PRIMARY_FONT_COLOR,
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "219%",
      textAlign: "center",
      textTransform: "none",
      letterSpacing: "0.155em",
    },
  },
  spacing: 10,
};
