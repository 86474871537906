import { Grid, styled, Typography } from "@mui/material";
import ContentContainer from "../layout/ContentContainer";
import ImageCard from "../shared/ImageCard";
import ActionButton from "../shared/ActionButton";

const BlogWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  background: theme.palette.primary.main,
  clipPath: "polygon(0 1%, 100% 10%, 100% 100%, 0% 100%)",
  WebkitClipPath: "polygon(0 1%, 100% 10%, 100% 100%, 0% 100%)",
  paddingTop: "360px",
  paddingBottom: "100px",
  WebkitBoxShadow: "inset 0px -200px 47px 0px rgba(88,201,179,0.08)",
  MozBoxShadow: "inset 0px -200px 47px 0px rgba(88,201,179,0.08)",
  boxShadow: "inset 0px -200px 47px 0px rgba(88,201,179,0.08)",
  marginTop: 300,

  [theme.breakpoints.up("sm")]: {
    marginTop: 260,
  },

  [theme.breakpoints.up("md")]: {
    marginTop: "unset",
    paddingTop: "240px",
  },

  "& .MuiTypography-h2": {
    color: theme.palette.background.paper,
    paddingBottom: "70px",
  },
}));

const MOCKED_DATA_BLOG = [
  {
    title: "Sophisticated cyberattacks from state groups to rise in 2023",
    publishedDate: "January 31, 2023",
    imageUrl:
      "https://uploads.teamt5.org/upload/original/Blog_thumbnail_Sophisticated%20cyberattacks%20from%20state%20groups%20to%20rise%20in%202023.jpg",
    blogUrl:
      "https://teamt5.org/en/posts/sophisticated-cyberattacks-from-state-groups-to-rise-in-2023/",
  },
  {
    title: "Hiding in Plain Sight: Obscuring C2s by Abusing CDN Services",
    publishedDate: "April 9, 2022",
    imageUrl:
      "https://uploads.teamt5.org/upload/original/202205_blog_pexels-soumil-kumar-735911_update.jpg",
    blogUrl:
      "https://teamt5.org/en/posts/hiding-in-plain-sight-obscuring-c2s-by-abusing-cdn-services/",
  },
  {
    title: "What is Advanced Persistent Threat(APT)?",
    publishedDate: "October 19, 2022",
    imageUrl:
      "https://uploads.teamt5.org/upload/original/APT_article_banner_pexels.jpg",
    blogUrl:
      "https://teamt5.org/en/posts/what-is-advanced-persistent-threat-apt/",
  },
];
const url = "https://teamt5.org/en/blog/";

const BlogSection = () => {
  return (
    <BlogWrapper id="blog">
      <ContentContainer>
        <Typography variant={"h2"}>Blog</Typography>
        <Grid
          container
          columnSpacing={{ xs: 2, md: 1, lg: 6, xl: 8 }}
          rowSpacing={{ xs: 5 }}
          columns={12}
        >
          {MOCKED_DATA_BLOG.map((blog) => {
            return (
              <Grid item xs={12} md={4} key={blog.imageUrl}>
                <ImageCard
                  title={blog.title}
                  imageUrl={blog.imageUrl}
                  publishedDate={blog.publishedDate}
                  blogUrl={blog.blogUrl}
                />
              </Grid>
            );
          })}
        </Grid>
        <ActionButton
          text={"view all"}
          color={"#031433"}
          sx={{ margin: "auto", marginTop: "80px" }}
          onClick={() => window.open(url, "_blank")}
        />
      </ContentContainer>
    </BlogWrapper>
  );
};
export default BlogSection;
