import { Box, styled, Typography } from "@mui/material";
import ContentContainer from "../layout/ContentContainer";
import React from "react";

const CareerHero = styled("div")(({ theme }) => ({
  width: "100%",
  height: "327px",
  backgroundImage: `url(${
    process.env.PUBLIC_URL + "/images/career-hero-safateam.png"
  })`,
  backgroundPosition: "right",
  backgroundSize: "cover",

  "& .MuiBox-root": {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
  },
}));

const HeroSection = ({ headline }: { headline: string }) => {
  return (
    <CareerHero>
      <ContentContainer style={{ height: "100%" }}>
        <Box>
          <Typography variant={"h3"} sx={{ mb: 2 }}>
            {headline}
          </Typography>
        </Box>
      </ContentContainer>
    </CareerHero>
  );
};

export default HeroSection;
