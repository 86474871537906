import React from "react";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import T5Logo from "../assets/T5Logo";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ContentContainer from "../layout/ContentContainer";

const SectionHolder = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse;",
  },
}));

const LeftText = styled("div")(({ theme }) => ({
  marginTop: "63px",
  marginBottom: "67px",
  width: "90%",
  [theme.breakpoints.down("md")]: {
    width: "70%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "25px",
  },
}));

const RightLogo = styled("div")(({ theme }) => ({
  marginTop: "63px",
  width: "10%",
  "& svg": {
    width: "100%",
    height: "135px",
    marginTop: "7px",
  },
  [theme.breakpoints.down("md")]: {
    width: "30%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "25px",
  },
}));

const ReadMore = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "auto",
  justifyItems: "center",
  alignItems: "center",
  cursor: "pointer",
  paddingTop: "18px",
  "& svg": {
    color: theme.palette.primary.light,
    width: "27px",
    height: "27px",
    marginRight: "5px",
  },
  "& a": {
    textDecoration: "none",
  },
}));

const TeamSection = () => {
  return (
    <ContentContainer>
      <SectionHolder id="teams">
        <LeftText>
          <Typography variant={"body1"}>
            SAFA maintains the professional reputation, excellence, and
            uncompromising standards of its key partner, Team T5, while focusing
            on generating value for customers based on the latest cutting edge
            research techniques.
          </Typography>
          <ReadMore>
            <ArrowForwardIcon />
            <a
              href={"https://teamt5.org/en/about-us/"}
              target={"_blank"}
              rel="noreferrer"
            >
              <Typography variant={"h4"} sx={{ letterSpacing: "0.1em" }}>
                Read more about Team T5
              </Typography>
            </a>
          </ReadMore>
        </LeftText>
        <RightLogo>
          <T5Logo />
        </RightLogo>
      </SectionHolder>
    </ContentContainer>
  );
};
export default TeamSection;
