import { styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ContentContainer from "../../layout/ContentContainer";
import ActionButton from "../../shared/ActionButton";

const ThreatIntelligenceSectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .txtHolder": {
    paddingTop: "25px",
    paddingBottom: "25px",
  },

  "& .lastP": {
    paddingBottom: "25px",
  },
}));

const ThreatIntelligenceSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
  return (
    <ContentContainer>
      <ThreatIntelligenceSectionWrapper>
        <div className={"txtHolder"}>
          <Typography variant={"h6"}>
            In the ever-evolving landscape of cyber threats, knowledge is power.
            That's why our Threat Intelligence and Malware Research service is
            dedicated to uncovering the secrets of malicious actors, providing
            invaluable insights into their tactics, techniques, and
            infrastructure.
          </Typography>
          <br />
          <Typography variant={"h6"}>
            That's why SAFA presents the Threat Vision product, an advanced
            cybersecurity solution that combines cutting-edge technology with
            comprehensive threat intelligence to provide unparalleled protection
            for your organization.
          </Typography>
          <br />
          <Typography variant={"h6"}>
            Threat Vision: Empowering Proactive Cybersecurity Defense
          </Typography>
          <br />
          <Typography variant={"h6"}>
            With cyber threats evolving at an alarming pace, it is essential to
            have a proactive defense strategy in place. Threat Vision, developed
            by TeamT5, is a powerful cybersecurity product that empowers
            organizations to stay one step ahead of adversaries and safeguard
            their digital assets.
          </Typography>
          <Typography variant={"h6"}>
            Threat Intelligence Factor: Unleashing Actionable Insights
          </Typography>

          <Typography variant={"h6"} className={"lastP"}>
            At the core of Threat Vision lies its exceptional Threat
            Intelligence Factor. TeamT5 has curated a vast network of
            intelligence sources, including global threat feeds, dark web
            monitoring, and advanced threat research. By leveraging artificial
            intelligence and machine learning algorithms, Threat Vision
            continuously gathers and analyzes massive volumes of data to extract
            actionable insights specific to your organization's threat
            landscape.
          </Typography>

          <ActionButton
            text={"Inquiry"}
            color={"#58C9B3"}
            onClick={() =>
              window.scrollTo({
                left: 0,
                top: isDesktop ? 820 : 1550,
                behavior: "smooth",
              })
            }
          />
        </div>
      </ThreatIntelligenceSectionWrapper>
    </ContentContainer>
  );
};

export default ThreatIntelligenceSection;
