import React, { useRef } from "react";
import { Box, styled, Typography } from "@mui/material";
import ContentContainer from "../layout/ContentContainer";
import TriangleContactUs from "../assets/TriangleContactUs";
import BasicInput from "../shared/BasicInput";
import ActionButton from "../shared/ActionButton";
import { Formik } from "formik";
import MapComponent from "../components/MapComponent";
import emailjs from "@emailjs/browser";

const ContactUsHolder = styled("div")(({ theme }) => ({
  background: theme.palette.primary.light,
  width: "100%",
  paddingTop: "50px",
  paddingBottom: "50px",
  ZIndex: "9",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "50px",
    paddingBottom: "50px",
  },
}));

const ContactWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
  display: "flex",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const LeftContacts = styled("div")(({ theme }) => ({
  width: "45%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },

  "& .contacts": {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
    },
  },
}));

const RightForm = styled("div")(({ theme }) => ({
  width: "40%",
  height: "100%",
  ZIndex: 10000,
  display: "flex",
  flexDirection: "column",
  rowGap: "25px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  "& .inputRow": {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      columnGap: "19px",
    },
  },
  "& .MuiInputBase-root": {
    marginBottom: "25px",
  },
  "& .buttonWrapper": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  "& .tellUs": {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "25px",
    },
  },
}));

const GoogleMap = styled("div")(() => ({
  width: "100%",
  height: "300px",
  marginTop: "25px",
  background: "#F8F9FA",
}));

const BgHolder = styled("div")(({ theme }) => ({
  width: "550px",
  height: "470px",
  position: "absolute",
  right: 0,
  marginTop: "225px",
  overflow: "hidden",
  display: "none",
  ZIndex: "10",
  [theme.breakpoints.up("sm")]: {
    display: "block",
    marginTop: "605px",
  },
  [theme.breakpoints.up("md")]: {
    width: "400px",
    height: "340px",
    marginTop: "248px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "550px",
    height: "470px",
    marginTop: "133px",
  },
  "& .MuiSvgIcon-root": {
    width: "100%",
    height: "100%",
  },
}));

const ContactUs = () => {
  const formRef: any = useRef();

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_nfa71tv",
        "template_pdccchn",
        formRef.current,
        "8eHa-WPsl8zYTRRZS"
      )
      .then(
        (result: any) => {
          alert("Form successfully submited!");
        },
        (error: any) => {
          alert("Form submission failed!");
        }
      );
  };

  return (
    <ContactUsHolder id="contact">
      <BgHolder>
        <TriangleContactUs />
      </BgHolder>
      <ContentContainer>
        <ContactWrapper>
          <Formik
            enableReinitialize={true}
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              message: "",
            }}
            onSubmit={(values, { resetForm }) => {
              sendEmail();
              resetForm();
              formRef.current.reset();
            }}
          >
            {({ values, handleSubmit }) => (
              <RightForm>
                <div className={"tellUs"}>
                  <Typography variant={"h2"}>Contact Us</Typography>
                  <Typography variant={"body2"} sx={{ lineHeight: "219%" }}>
                    Tell us how we can help you, and we'll be in touch right
                    away.
                  </Typography>
                </div>
                <form ref={formRef}>
                  <Box className={"inputRow"}>
                    <BasicInput label={"First Name"} name={"first_name"} />
                    <BasicInput label={"Last Name"} name={"last_name"} />
                  </Box>

                  <BasicInput label={"e-mail"} name={"email"} />
                  <BasicInput label={"Message"} name={"message"} rows={3} />
                  <Box className={"buttonWrapper"}>
                    <ActionButton
                      onClick={handleSubmit}
                      text={"submit"}
                      color={"#58C9B3"}
                      sx={{ marginTop: "30px" }}
                    />
                  </Box>
                </form>
              </RightForm>
            )}
          </Formik>

          <LeftContacts>
            <div className={"contacts"}>
              <Typography variant={"h6"} sx={{ lineHeight: "230%" }}>
                SAFA Team GmbH <br />
                Address: Teinfaltstraße 8/8a 1010 Vienna
                <br />
                email:{" "}
                <a
                  href={"mailto:hello@safateam.com"}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  hello@safateam.com
                </a>
              </Typography>
              <GoogleMap>
                <MapComponent />
              </GoogleMap>
            </div>
          </LeftContacts>
        </ContactWrapper>
      </ContentContainer>
    </ContactUsHolder>
  );
};

export default ContactUs;
