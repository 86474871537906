import HeroSection from "../shared/HeroSection";
import { styled } from "@mui/material";
import { useParams } from "react-router-dom";
import SecurityAssessmentSection from "../sections/service/SecurityAssessmentSection";
import ContacUsSection from "../sections/ContacUsSection";
import TrainingSection from "../sections/service/TrainingSection";
import ThreatIntelligenceSection from "../sections/service/ThreatIntelligenceSection";
import NetworkHardendingSection from "../sections/service/NetworkHardendingSection";
import ReverseEngineeringSection from "../sections/service/ReverseEngineering";

const OurServicesPageWrapper = styled("div")(({ theme }) => ({
  width: "100%",
}));

const SECURITY_ASSESSMENT_URL = "security-assessment";
const TRAINING_URL = "training";
const THREAT_INTELLIGENCE_URL = "threat-intelligence";
const NETWORK_HARDENING_CONSULTING_URL = "network-hardening-consulting";
const REVERSE_ENGINEERING_URL =
  "reverse-engineering-and-vulnerability-research";

const OurServicesPage = () => {
  const { subpage } = useParams();
  const getHeadline = (): string => {
    if (subpage === SECURITY_ASSESSMENT_URL) {
      return "Security Assessment";
    } else if (subpage === TRAINING_URL) {
      return "Training";
    } else if (subpage === NETWORK_HARDENING_CONSULTING_URL) {
      return "Network Hardening Consulting";
    } else if (subpage === REVERSE_ENGINEERING_URL) {
      return "Reverse Engineering and Vulnerability research";
    } else if (subpage === THREAT_INTELLIGENCE_URL) {
      return "Threat Intelligence";
    }
    return "";
  };

  return (
    <OurServicesPageWrapper>
      <HeroSection headline={getHeadline()} />
      {subpage === SECURITY_ASSESSMENT_URL && <SecurityAssessmentSection />}
      {subpage === TRAINING_URL && <TrainingSection />}
      {subpage === THREAT_INTELLIGENCE_URL && <ThreatIntelligenceSection />}
      {subpage === NETWORK_HARDENING_CONSULTING_URL && (
        <NetworkHardendingSection />
      )}
      {subpage === REVERSE_ENGINEERING_URL && <ReverseEngineeringSection />}
      <ContacUsSection />
    </OurServicesPageWrapper>
  );
};

export default OurServicesPage;
