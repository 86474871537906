import { styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ContentContainer from "../../layout/ContentContainer";
import ActionButton from "../../shared/ActionButton";

const TrainingSectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .txtHolder": {
    paddingTop: "25px",
    paddingBottom: "25px",
  },

  "& .lastP": {
    paddingBottom: "25px",
  },
}));

const TrainingSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
  return (
    <ContentContainer>
      <TrainingSectionWrapper>
        <div className={"txtHolder"}>
          <Typography variant={"h6"}>
            Empowering Teams to Establish a Strong Security Posture and Mitigate
            Risks
          </Typography>
          <br />
          <Typography variant={"h6"}>
            At SAFA, we recognize that a well-trained and knowledgeable team is
            a critical asset in today's rapidly evolving cyber threat landscape.
            That's why we offer comprehensive technical training in cyber
            security, designed to equip your team with the skills, expertise,
            and insights they need to establish a robust security posture,
            mitigate risks, understand system internals, and leverage threat
            intelligence effectively.
          </Typography>
          <br />
          <Typography variant={"h6"}>
            Our Technical Training goes beyond theoretical concepts and textbook
            knowledge. We provide hands-on, practical training sessions that
            empower your team to tackle real-world security challenges head-on.
            With a focus on industry best practices, emerging trends, and the
            latest tools and techniques, we ensure that your team is equipped
            with the knowledge and skills to defend your organization against
            cyber threats effectively.
          </Typography>
          <br />
          <Typography variant={"h6"} className={"lastP"}>
            Establishing a Strong Security Posture:&nbsp; Our training program
            is tailored to help your team establish a strong security posture
            that aligns with your organization's unique needs and objectives. We
            start by assessing your existing security measures and identifying
            areas of improvement. Through interactive training sessions, we
            address the key components of a robust security posture, including
            network security, application security, data protection, access
            control, incident response, and more. By providing your team with a
            comprehensive understanding of these crucial elements, we enable
            them to implement effective security measures and safeguard your
            organization's valuable assets.
          </Typography>

          <ActionButton
            text={"Inquiry"}
            color={"#58C9B3"}
            onClick={() =>
              window.scrollTo({
                left: 0,
                top: isDesktop ? 850 : 1700,
                behavior: "smooth",
              })
            }
          />
        </div>
      </TrainingSectionWrapper>
    </ContentContainer>
  );
};

export default TrainingSection;
