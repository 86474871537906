import { Box, Fade, Link, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import LinkedInIcon from "../assets/LinkedInIcon";
import TwitterIcon from "../assets/TwitterIcon";
import { useNavigate } from "react-router-dom";

export const MobileNav = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  background: theme.palette.primary.main,
  position: "absolute",
  top: 80,
  right: 0,
  left: 0,
  height: "calc(100vh - 80px)",
  width: "100%",
  zIndex: 10,

  "& .mobileLinks": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "52px",
    margin: "auto",
    color: "red",

    "& .MuiTypography-body2": {
      color: theme.palette.background.paper,
      cursor: "pointer",
    },
  },
}));

type Props = {
  navLinks: Item[];
  setMenuOpen: (open: boolean) => void;
  pathname: string;
  setUrl: (url: string) => void;
};
type Item = {
  name: string;
  url: string;
};

const MobileNavigation = ({
  navLinks,
  setMenuOpen,
  pathname,
  setUrl,
}: Props) => {
  const navigate = useNavigate();

  return (
    <MobileNav>
      <Fade in={true}>
        <Box className={"mobileLinks"}>
          {navLinks.map((link, i) => {
            return (
              <Link
                key={link.name + i}
                onClick={() => {
                  setMenuOpen(false);
                  navigate("/");
                  setUrl(link.url);
                }}
              >
                <Typography variant={"body2"}>{link.name}</Typography>
              </Link>
            );
          })}
          <Typography
            variant={"body2"}
            onClick={() => {
              setUrl("career");
              navigate("/career");
              setMenuOpen(false);
            }}
          >
            CAREER
          </Typography>
          <Box className={"socialIcons"} sx={{ pt: 5 }}>
            <LinkedInIcon />
            <TwitterIcon />
          </Box>
        </Box>
      </Fade>
    </MobileNav>
  );
};
export default MobileNavigation;
