import { InputLabel, styled, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFormikContext } from "formik";

const InputWrapper = styled("div")(() => ({
  width: "100%",
}));

const StyledInput = styled(TextField)(() => ({
  borderRadius: "0px",

  "& .MuiFilledInput-root:before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-root": {
    borderRadius: "0px",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& .MuiInputBase-multiline": {
    padding: 0,
  },
}));

type Props = {
  label: string;
  name: string;
  type?: "text" | "date" | "password";
  rows?: number | undefined;
};

const BasicInput: React.FC<Props> = ({
  label,
  name,
  type = "text",
  rows = undefined,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { handleChange } = useFormikContext();

  return (
    <InputWrapper>
      <InputLabel shrink>
        <Typography
          variant={"body2"}
          sx={{ color: "#FFFFFF", textTransform: "capitalize" }}
        >
          {label}
        </Typography>
      </InputLabel>
      <StyledInput
        multiline={!!rows}
        rows={rows}
        fullWidth
        variant="filled"
        type={isFocused || type === "password" ? type : "text"}
        name={name}
        onFocus={() => setIsFocused(true)}
        onChange={handleChange}
        inputProps={{
          style: {
            background: "#F5F6F7",
            borderRadius: "0px",
            color: "#334144",
            fontFamily: "Rubik",
            fontSize: "18px",
            paddingLeft: "10px",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
        InputProps={{ disableUnderline: true }}
      />
    </InputWrapper>
  );
};
export default BasicInput;
