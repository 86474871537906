import { styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ContentContainer from "../../layout/ContentContainer";

const DataSectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .para": {
    paddingTop: "25px",
  },

  "& .lastP": {
    paddingBottom: "25px",
  },

  "& ul": {
    fontFamily: "Rubik",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "150%",
  },
}));

const DataSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line
  return (
    <ContentContainer>
      <DataSectionWrapper>
        <Typography variant={"h5"} className={"para"}>
          Definitions
        </Typography>
        <Typography variant={"h6"}>
          Our data protection declaration should be simple and understandable
          for everyone. In this data protection declaration, the official terms
          of the General Data Protection Regulation (GDPR) are generally used.
          The official definitions are explained in &nbsp;
          <a
            href={`https://gdpr.eu/article-4-definitions/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 4 GDPR
          </a>
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Access to and storage of information in terminal equipment
        </Typography>
        <Typography variant={"h6"}>
          By using our website, information (e.g. IP address) can be accessed or
          information (e.g. cookies) stored in your end devices. This access or
          storage may involve further processing of personal data within the
          meaning of the GDPR. <br />
          In cases where such access to information or such storage of
          information is absolutely necessary for the technically error-free
          provision of our services, this is done on the basis of General Data
          Protection Regulation (GDPR). <br />
          In cases in which such a process serves other purposes (e.g. the
          needs-based design of our website), this will only take place with
          your consent in accordance with Article 6 (1) (a) GDPR. <br />
          The consent can be revoked at any time for the future. The provisions
          of the GDPR apply to the processing of your personal data. <br />
          Further information on the processing of your personal data and the
          relevant legal bases in this context can be found in the following
          sections on the specific processing activities on our website. <br />
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Web Hosting
        </Typography>
        <Typography variant={"h6"}>
          This website is hosted by an external service provider (Google). This
          website is hosted in Groningen, Netherlands. Personal data collected
          on this website is stored on the host's servers. This can primarily be
          IP addresses, contact requests, meta and communication data, website
          access and other data generated via a website.. <br />
          We collect the listed data in order to be able to guarantee a smooth
          connection to the website and a technically error-free provision of
          our services. The processing of this data is absolutely necessary in
          order to make the website available to you. The legal basis for
          processing the data is our legitimate interest in the correct
          presentation and functionality of our website in accordance with
          Article 6 (1) (f) GDPR. <br />
          We have concluded a data processing agreement with the provider in
          accordance with the requirements of Art. 28 GDPR, in which we oblige
          them to protect our customers' data and not to pass it on to third
          parties. <br />
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Server log files
        </Typography>
        <Typography variant={"h6"}>
          When you visit our websites, it is technically necessary for data to
          be transmitted to our web server via your internet browser. The
          following data is recorded during an ongoing connection for
          communication between your internet browser and our web server:
        </Typography>
        <ul className={"list"}>
          <li className={"item"}>Date and time of the request</li>
          <li className={"item"}>Name of the requested file</li>
          <li className={"item"}>Page from which the file was requested</li>
          <li className={"item"}>access status</li>
          <li className={"item"}>Web browser and operating system used</li>
          <li className={"item"}>
            (Full) IP address of the requesting computer
          </li>
          <li className={"item"}>Amount of data transferred</li>
        </ul>
        <Typography variant={"h6"}>
          We collect the listed data in order to ensure a smooth connection to
          the website and to enable users to use our website comfortably. In
          addition, the log file is used to evaluate system security and
          stability as well as for administrative purposes. The legal basis for
          the temporary storage of the data or log files is &nbsp;
          <a
            href={`https://gdpr.eu/article-6-how-to-process-personal-data-legally/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Article 6 (1) (f) GDPR.
          </a>
        </Typography>
        <Typography variant={"h6"}>
          For technical security, in particular to prevent attempts to attack
          our web server, we store this data for a short time. Based on this
          data, it is not possible for us to draw conclusions about individual
          persons. After seven days at the latest, the data is anonymized by
          shortening the IP address at domain level, so that it is no longer
          possible to establish a reference to the individual user. The data may
          also be processed anonymously for statistical purposes. A storage of
          this data together with other personal data of the user, a comparison
          with other databases or a transfer to third parties does not take
          place at any time.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Cookies
        </Typography>
        <Typography variant={"h6"}>
          Our website does not store any cookies.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Contact form and contact via email
        </Typography>
        <Typography variant={"h6"}>
          If you send us inquiries via the contact form or e-mail, your details
          from the inquiry form or your email, including the contact details you
          provided there, will be stored by us for the purpose of processing the
          inquiry and in the event of follow-up questions. Providing an e-mail
          address is required for contact information, providing your name and
          telephone number is voluntary. Under no circumstances will we pass on
          this data without your consent. The legal basis for processing the
          data is our legitimate interest in answering your request in
          accordance with Article 6 Paragraph 1 Letter f GDPR and, if
          applicable, Article 6 Paragraph 1 Letter b GDPR, if your request is
          aimed at concluding a contract. Your data will be deleted after your
          request has been processed, provided that there are no legal storage
          requirements to the contrary. In the case of Art. 6 Para. 1 lit. f
          GDPR, you can object to the processing of your personal data at any
          time.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Data transfer and recipients
        </Typography>
        <Typography variant={"h6"}>
          A transfer of your personal data to third parties does not take place,
          except
        </Typography>
        <ul className={"list"}>
          <li className={"item"}>
            if we have explicitly pointed this out in the description of the
            respective data processing,
          </li>
          <li className={"item"}>
            if you have given your express consent in accordance with Article 6
            Paragraph 1 Clause 1 Letter a GDPR,
          </li>
          <li className={"item"}>
            the transfer according to Article 6 paragraph 1 sentence 1 lit. f
            GDPR is necessary to assert, exercise or defend legal claims and
            there is no reason to assume that you have an overriding legitimate
            interest in not disclosing your data,
          </li>
          <li className={"item"}>
            in the event that there is a legal obligation for the transfer
            according to Art. 6 Para. 1 S. 1 lit. c GDPR and
          </li>
          <li className={"item"}>
            insofar as this is necessary for the processing of contractual
            relationships with you in accordance with Article 6 (1) sentence 1
            lit. b GDPR.
          </li>
        </ul>
        <Typography variant={"h6"}>
          We also use external service providers for the processing of our
          services, which we have carefully selected and commissioned in
          writing. You are bound by our instructions and are regularly checked
          by us. With whom we may have concluded order processing contracts in
          accordance with Art. 28 GDPR. These are service providers responsible
          for web hosting, sending e-mails and maintaining and maintaining our
          IT systems, etc. The service providers will not pass this data on to
          third parties.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Duration of storage of personal data
        </Typography>
        <Typography variant={"h6"}>
          The duration of the storage of personal data is based on the relevant
          statutory retention periods (e.g. from commercial law and tax law).
          After the respective period has expired, the corresponding data is
          routinely deleted. If data is required to fulfill or initiate a
          contract or if we have a legitimate interest in further storage, the
          data will be deleted if they are no longer required for these purposes
          or if you exercise your right of revocation or objection.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Your rights
        </Typography>
        <Typography variant={"h6"}>
          In the following you will find information on which data subject
          rights the applicable data protection law grants you in relation to
          the person responsible with regard to the processing of your personal
          data:
        </Typography>
        <Typography variant={"h6"}>
          The right to request information about your personal data processed by
          us in accordance with{" "}
          <a
            href={`https://gdpr.eu/article-15-right-of-access/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 15 GDPR{" "}
          </a>{" "}
          . In particular, you can obtain information about the processing
          purposes, the category of personal data, the categories of recipients
          to whom your data was or will be disclosed, the planned storage
          period, the existence of a right to correction, deletion, restriction
          of processing or objection, the existence of a Right to complain, the
          origin of your data, if not collected from us, and the existence of
          automated decision-making including profiling and, if necessary,
          meaningful information about their details.
        </Typography>
        <Typography variant={"h6"}>
          The right, in accordance with &nbsp;
          <a
            href={`https://gdpr.eu/article-16-right-to-rectification/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 16 GDPR{" "}
          </a>
          &nbsp; to immediately request the correction of incorrect or
          incomplete personal data stored by us.
        </Typography>
        <Typography variant={"h6"}>
          The right, in accordance with &nbsp;
          <a
            href={`https://gdpr.eu/article-17-right-to-be-forgotten/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 17 GDPR,
          </a>
          &nbsp; to request the deletion of your personal data stored by us,
          unless the processing is necessary to exercise the right to freedom of
          expression and information, to fulfill a legal obligation, for reasons
          of public interest or to assert, exercise or Defense of legal claims
          is required.
        </Typography>
        <Typography variant={"h6"}>
          The right, in accordance with &nbsp;
          <a
            href={`https://gdpr.eu/article-18-right-to-restriction-of-processing/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 18 GDPR,
          </a>
          &nbsp; to request the restriction of the processing of your personal
          data, insofar as you dispute the accuracy of the data, the processing
          is unlawful, but you refuse to delete it and we no longer need the
          data, but you use them for you need to assert, exercise or defend
          legal claims or you have objected to processing in accordance with
          &nbsp;
          <a
            href={`https://gdpr.eu/article-21-right-to-object/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 21 GDPR .
          </a>
        </Typography>
        <Typography variant={"h6"}>
          You have the right to be informed in accordance with &nbsp;
          <a
            href={`https://gdpr.eu/article-19-notification-obligation/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 19 GDPR
          </a>
          &nbsp; if you have asserted the right to correction, deletion or
          restriction of processing to the person responsible. This obliges you
          to notify all recipients to whom your personal data has been disclosed
          of this correction or deletion of the data or restriction of
          processing, unless this proves impossible or involves a
          disproportionate effort. You have the right to be informed about these
          recipients.
        </Typography>
        <Typography variant={"h6"}>
          Right to data transferability in accordance with &nbsp;
          <a
            href={`https://gdpr.eu/article-20-right-to-data-portability/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 20 GDPR
          </a>
          &nbsp; : You have the right to receive your personal data that you
          have provided to us in a structured, common and machine-readable
          format or to request transmission to another person responsible,
          insofar as this is technically feasible.
        </Typography>
        <Typography variant={"h6"}>
          You have the right to complain to a supervisory authority in
          accordance with &nbsp;
          <a
            href={`https://gdpr.eu/article-77-data-subjects-right-to-lodge-a-complaint/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 77 GDPR
          </a>
          &nbsp; As a rule, you can contact the supervisory authority of the
          federal state in which we are based or, if applicable, that of your
          usual place of residence or work.
        </Typography>
        <Typography variant={"h6"}>
          Right to revoke granted consent in accordance with &nbsp;
          <a
            href={`https://gdpr.eu/article-7-how-to-get-consent-to-collect-personal-data/`}
            target={"_blank"}
            rel="noreferrer"
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            Art. 7 Para. 3 GDPR
          </a>
          &nbsp; : You have the right to revoke your consent to the processing
          of data at any time with effect for the future. In the event of
          revocation, we will delete the data concerned immediately, unless
          further processing can be based on a legal basis for processing
          without consent. The revocation of the consent does not affect the
          legality of the processing carried out on the basis of the consent up
          to the point of revocation.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Right to object
        </Typography>
        <Typography variant={"h6"}>
          If your personal data is processed by us on the basis of legitimate
          interests in accordance with Article 6 Paragraph 1 Sentence 1 lit. f
          GDPR, you have the right under Article 21 GDPR to object to the
          processing of your personal data, insofar as this reasons arising from
          your particular situation. Insofar as the objection is directed
          against the processing of personal data for the purpose of direct
          advertising, you have a general right of objection without the need to
          state a particular situation.
        </Typography>
        <Typography variant={"h6"}>
          If you would like to make use of your right of revocation or
          objection, an e-mail to &nbsp;
          <a
            href={"mailto:hello@safateam.com"}
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            hello@safateam.com
          </a>
          &nbsp; is sufficient
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          External links
        </Typography>
        <Typography variant={"h6"}>
          Insofar as there are links to other websites, we have neither
          influence nor control over the linked content and the data protection
          regulations there. We recommend checking the data protection
          declarations of these websites when accessing linked websites in order
          to be able to determine whether and to what extent personal data is
          collected, processed, used or made accessible to third parties.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Legal Obligations
        </Typography>
        <Typography variant={"h6"}>
          The provision of personal data for the decision on the conclusion of a
          contract, the fulfillment of the contract or the implementation of
          pre-contractual measures is voluntary. However, we can only make the
          decision within the framework of contractual measures if you provide
          such personal data that is necessary for the conclusion of the
          contract, the fulfillment of the contract or pre-contractual measures.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Automated Decision Making
        </Typography>
        <Typography variant={"h6"}>
          Automated decision-making or profiling according to &nbsp;
          <a
            href={
              "https://gdpr.eu/article-22-automated-individual-decision-making/"
            }
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            {" "}
            Art. 22 GDPR
          </a>
          &nbsp; does not take place.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Subject to change
        </Typography>
        <Typography variant={"h6"}>
          We reserve the right to adapt or update this data protection
          declaration if necessary, taking into account the applicable data
          protection regulations. In this way, we can adapt them to the current
          legal requirements and take changes in our services into account, e.g.
          B. when introducing new services. The current version applies to your
          visit.
        </Typography>
        <Typography variant={"h6"}>
          Status of this data protection declaration: April 2023
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Data protection
        </Typography>
        <Typography variant={"h6"}>
          Responsible body within the meaning of data protection law:
        </Typography>
        <Typography variant={"h6"} className={"lastP"}>
          SAFA Team GmbH <br />
          Teinfaltstraße 8/8a <br />
          1010 Vienna
          <br />
          email: hello@safateam.com <br />
          {/*Telephone +43  <br />*/}
          Contact details of the data protection officer:{" "}
          <a
            href={"mailto:hello@safateam.com"}
            style={{ textDecoration: "underline", color: "#48474C" }}
          >
            hello@safateam.com
          </a>
        </Typography>
      </DataSectionWrapper>
    </ContentContainer>
  );
};

export default DataSection;
