import { styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ContentContainer from "../../layout/ContentContainer";
import ActionButton from "../../shared/ActionButton";

const ReverseEngineeringSectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .txtHolder": {
    paddingTop: "25px",
    paddingBottom: "25px",
  },

  "& .lastP": {
    paddingBottom: "25px",
  },

  "& ul": {
    fontFamily: "Rubik",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "150%",
    listStyleType: "decimal",
  },
}));

const ReverseEngineeringSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
  return (
    <ContentContainer>
      <ReverseEngineeringSectionWrapper>
        <div className={"txtHolder"}>
          <Typography variant={"h6"}>
            SAFA Cyber Security Consulting: Unleashing the Power of Reverse
            Engineering and vulnerability research
          </Typography>
          <br />
          <Typography variant={"h6"}>
            SAFA offers top-tier Cyber Security Consulting services,
            specializing in the art of reverse engineering and vulnerability
            research. With a team of experienced vulnerability researchers at
            the helm, we provide our clients with high-end services to uncover
            hidden security flaws and fortify their digital defenses.
          </Typography>
          <br />

          <Typography variant={"h6"} className={"lastP"}>
            Advanced Reverse Engineering: Our experts excel in reverse
            engineering complex software and systems, dissecting code layers to
            reveal vulnerabilities and design flaws that could be exploited by
            cyber attackers. Through meticulous analysis, we uncover critical
            insights and provide actionable recommendations.
          </Typography>

          <Typography variant={"h6"} className={"lastP"}>
            Skilled Vulnerability Researchers: Our team comprises seasoned
            vulnerability researchers with a deep understanding of the latest
            threats and attack vectors. Leveraging their expertise, we identify
            and prioritize vulnerabilities, allowing you to focus your resources
            on addressing the most critical risks.
          </Typography>

          <ActionButton
            text={"Inquiry"}
            color={"#58C9B3"}
            onClick={() =>
              window.scrollTo({
                left: 0,
                top: isDesktop ? 720 : 1300,
                behavior: "smooth",
              })
            }
          />
        </div>
      </ReverseEngineeringSectionWrapper>
    </ContentContainer>
  );
};

export default ReverseEngineeringSection;
