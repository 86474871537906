import { Container } from "@mui/material";
import React from "react";
import Navigation from "./Navigation";
import FooterSection from "../sections/Footer";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <Container maxWidth={false} disableGutters={true}>
      <Navigation />
      {children}
      <FooterSection />
    </Container>
  );
};
export default Layout;
