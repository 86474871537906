import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./layout/Layout";
import Career from "./pages/Career";
import DataProtection from "./pages/DataProtection";
import OurServicesPage from "./pages/OurServicesPage";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path={"/"} element={<Home />}></Route>
        <Route path={"/career"} element={<Career />}></Route>
        <Route path={"/privacy"}>
          <Route path={""} element={<DataProtection />}></Route>
          <Route path={":subpage"} element={<DataProtection />}></Route>
        </Route>
        <Route path={"/our-services"}>
          <Route path={""} element={<OurServicesPage />}></Route>
          <Route path={":subpage"} element={<OurServicesPage />}></Route>
        </Route>
      </Routes>
    </Layout>
  );
}

export default App;
