import { Box, styled, Typography } from "@mui/material";
import React, { CSSProperties } from "react";

const StyledButton = styled("div")(({ theme }) => ({
  clipPath: "polygon(25px 0, 100% 0, 100% 100%, 0 100%, 0 24px)",
  WebkitClipPath: "polygon(25px 0, 100% 0, 100% 100%, 0 100%, 0 24px)",
  boxSizing: "border-box",
  background: "#FFFFFF",
  width: 225,
  height: "65px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",

  "& .button": {
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    clipPath: "polygon(24px 0, 100% 0, 100% 100%, 0 100%, 0 23px)",
    WebkitClipPath: "polygon(24px 0, 100% 0, 100% 100%, 0 100%, 0 23px)",
    width: 220,

    "&:hover": {
      background: theme.palette.background.paper,

      "& .MuiTypography-body2": {
        color: theme.palette.primary.light,
        fontWeight: 700,
      },
    },
    "& .MuiTypography-body2": {
      textTransform: "uppercase",
      color: theme.palette.background.paper,
      fontWeight: 500,
      letterSpacing: "0.155em",
    },
  },
}));

type Props = {
  text: string;
  color: string;
  sx?: CSSProperties;
  onClick?: () => void;
};

const ActionButton: React.FC<Props> = ({ text, color, sx, onClick }) => {
  return (
    <StyledButton sx={sx} onClick={onClick}>
      <Box className={"button"} sx={{ background: color }}>
        <Typography variant={"body2"}>{text}</Typography>
      </Box>
    </StyledButton>
  );
};
export default ActionButton;
