import { SvgIcon } from "@mui/material";

const TwitterIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        d="M16 32.2362C7.16418 32.2362 0 25.072 0 16.2362C0 7.40034 7.16418 0.23616 16 0.23616C24.8358 0.23616 32 7.40034 32 16.2362C32 25.072 24.8358 32.2362 16 32.2362ZM16 1.43019C7.8806 1.43019 1.19403 8.11676 1.19403 16.2362C1.19403 24.3556 7.8806 31.0421 16 31.0421C24.1194 31.0421 30.806 24.3556 30.806 16.2362C30.806 8.11676 24.1194 1.43019 16 1.43019Z"
        fill="white"
      />
      <path
        d="M24.597 10.9824C23.8806 11.2212 23.403 11.46 22.9254 11.46C23.1642 11.46 23.6418 10.9824 23.6418 10.7436C23.8806 10.5048 24.1194 10.0272 24.1194 9.78839C23.403 10.0272 22.9254 10.266 22.209 10.266C22.209 10.266 22.209 10.266 21.9702 10.266C21.9702 10.266 21.9702 10.0272 21.7314 10.0272C21.4926 9.78839 21.2537 9.78839 20.7761 9.54958C20.2985 9.31078 19.8209 9.31078 19.3433 9.31078C18.8657 9.31078 18.3881 9.54958 17.9105 9.78839C17.4329 10.0272 16.9552 10.266 16.7164 10.7436C16.4776 10.9824 16.2388 11.46 16 11.9376C16 12.4153 16 12.8929 16 13.3705C13.1343 13.1317 10.9851 12.1764 9.07465 10.0272H8.83584C8.11942 11.2212 8.35823 13.1317 9.55226 14.0869C9.55226 14.3257 9.79106 14.3257 9.79106 14.5645C9.79106 14.5645 9.07465 14.5645 8.35823 14.0869H8.11942C8.11942 14.3257 8.11942 14.3257 8.11942 14.5645C8.59703 15.9973 9.55226 16.9526 10.5075 17.4302C10.7463 17.4302 10.7463 17.4302 10.9851 17.669C10.7463 17.669 10.5075 17.669 9.79106 17.669C9.79106 17.669 9.55226 17.669 9.79106 17.9078C10.2687 19.5794 11.7015 20.057 12.6567 20.2958C12.8955 20.2958 12.8955 20.2958 13.1343 20.2958C12.4179 20.5347 11.4627 21.0123 10.9851 21.2511C10.0299 21.4899 9.07465 21.4899 8.11942 21.4899C7.88062 21.4899 7.88062 21.4899 7.88062 21.4899C8.11942 21.7287 8.11942 21.9675 8.35823 21.9675C9.07465 22.2063 9.55226 22.4451 10.2687 22.6839C13.612 23.6391 17.6717 22.9227 20.0597 20.2958C21.9702 18.3854 22.6866 15.5197 22.6866 12.6541L22.9254 12.4153C23.403 11.9376 23.8806 11.46 24.3582 10.9824C24.597 11.2212 24.597 10.9824 24.597 10.9824Z"
        fill="white"
      />
    </SvgIcon>
  );
};
export default TwitterIcon;
