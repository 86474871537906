import React from "react";
import { styled } from "@mui/material";
import HeroSection from "../sections/HeroSection";
import JoinUsSection from "../sections/JoinUsSection";
import TeamSection from "../sections/TeamSection";
import OurServicesSection from "../sections/OurServicesSection";
import BlogSection from "../sections/BlogSection";
import ContactUs from "../sections/ContacUsSection";

const HomeWrapper = styled("div")(({ theme }) => ({
  width: "100%",
}));

const Home = () => {
  return (
    <HomeWrapper>
      <HeroSection />
      <TeamSection />
      <JoinUsSection />
      <OurServicesSection />
      <BlogSection />
      <ContactUs />
    </HomeWrapper>
  );
};
export default Home;
