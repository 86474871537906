import { styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ContentContainer from "../../layout/ContentContainer";

const ImprintSectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .para": {
    paddingTop: "25px",
  },

  "& .lastP": {
    paddingBottom: "25px",
  },
}));

const ImprintSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line
  return (
    <ContentContainer>
      <ImprintSectionWrapper>
        <Typography variant={"h5"} className={"para"}>
          Imprint & Disclosure according to § 25 of the Media Act
        </Typography>
        <Typography variant={"h6"} className={"lastP"}>
          SAFA Team GmbH
          <br />
          Teinfaltstraße 8/8a 1010
          <br />
          1010 Vienna
          <br />
          email: hello@safateam.com
          <br />
          CEO: Andrijana Sekularac
          <br />
          Firmenbuchnummer: FN: 582815g
          <br />
          Amtsgericht: Wien
        </Typography>
      </ImprintSectionWrapper>
    </ContentContainer>
  );
};

export default ImprintSection;
