import React from "react";
import { Box, Container, styled, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FooterHolder = styled("div")(({ theme }) => ({
  display: "flex",
  background: theme.palette.primary.main,
  height: "140px",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    height: "200px",
  },
  "@media screen and (max-width: 700px)": {
    height: "200px",
  },

  "& .contentWrapper": {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
}));

const TextHolder = styled("div")(({ theme }) => ({
  display: "flex",
  width: "650px",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "column-reverse",
  },
  "@media screen and (max-width: 700px)": {
    width: "100%",
    flexDirection: "column-reverse",
  },
}));

const CurrentYear = new Date().getFullYear();

const FooterSection = () => {
  const navigate = useNavigate();
  return (
    <FooterHolder>
      <Container maxWidth="xl" sx={{ margin: "57px auto 57px auto" }}>
        <Box className={"contentWrapper"}>
          <TextHolder>
            <Typography variant={"subtitle1"} sx={{ color: "white" }}>
              © SAFA Team GmbH {CurrentYear}
            </Typography>
            <Link>
              <Typography
                variant={"subtitle1"}
                sx={{ color: "white", cursor: "pointer" }}
                onClick={() => navigate("privacy/data-protection")}
              >
                Privacy & Cookies Policy
              </Typography>
            </Link>
            <Typography
              variant={"subtitle1"}
              sx={{ color: "white", cursor: "pointer" }}
              onClick={() => navigate("privacy/terms-of-use")}
            >
              Terms of Use
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{ color: "white", cursor: "pointer" }}
              onClick={() => navigate("privacy/imprint")}
            >
              Imprint
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{ color: "white", cursor: "pointer" }}
              onClick={() => navigate("privacy/responsible-disclosure-policy")}
            >
              Responsible Disclosure Policy
            </Typography>
          </TextHolder>
        </Box>
      </Container>
    </FooterHolder>
  );
};
export default FooterSection;
