import { styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ContentContainer from "../../layout/ContentContainer";
import ActionButton from "../../shared/ActionButton";

const NetworkHardeningSectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .txtHolder": {
    paddingTop: "25px",
    paddingBottom: "25px",
  },

  "& .lastP": {
    paddingBottom: "25px",
  },

  "& ul": {
    fontFamily: "Rubik",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "150%",
    listStyleType: "decimal",
  },
}));

const NetworkHardendingSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
  return (
    <ContentContainer>
      <NetworkHardeningSectionWrapper>
        <div className={"txtHolder"}>
          <Typography variant={"h6"}>
            At SAFA, a trusted cyber security company, we offer expert Network
            Hardening Consulting services to help fortify your organization's
            digital infrastructure against relentless cyber threats. Our team of
            seasoned professionals specializes in assessing and enhancing the
            security of your network, ensuring that your systems are robust,
            resilient, and resistant to attacks
          </Typography>
          <br />
          <Typography variant={"h6"}>
            Key Features of Network Hardening Consulting:
          </Typography>
          <br />

          <ul className={"list"}>
            <li className={"item"}>
              Tailored Security Solutions: Based on the assessment findings, we
              develop customized security solutions that align with your
              organization's unique requirements and objectives. Our experts
              work closely with you to implement measures that address
              identified weaknesses and mitigate potential risks effectively.
            </li>
            <li className={"item"}>
              Firewall Optimization: Firewalls are the first line of defense for
              your network. Our consultants optimize your firewall rules,
              strengthen access controls, and eliminate unnecessary open ports,
              enhancing your network's security while maintaining optimal
              performance.
            </li>
            <li className={"item"}>
              Access Control and Authentication: We help you implement robust
              access control mechanisms, ensuring that only authorized personnel
              can access critical resources within your network. From
              multi-factor authentication to role-based access controls, we
              tailor solutions that balance security with usability.
            </li>
            <li className={"item"}>
              Continuous Monitoring and Threat Intelligence Integration: Network
              hardening is an ongoing process. We assist in setting up
              continuous monitoring mechanism to detect and respond to potential
              security incidents promptly. By integrating threat intelligence
              feeds, we enhance your network's ability to identify and mitigate
              emerging threats.
            </li>
          </ul>

          <Typography variant={"h6"} className={"lastP"}>
            Choose SAFA for Network Hardening Consulting and strengthen your
            network's defenses against evolving cyber threats. Contact us today
            to fortify your digital fortress
          </Typography>

          <ActionButton
            text={"Inquiry"}
            color={"#58C9B3"}
            onClick={() =>
              window.scrollTo({
                left: 0,
                top: isDesktop ? 900 : 1850,
                behavior: "smooth",
              })
            }
          />
        </div>
      </NetworkHardeningSectionWrapper>
    </ContentContainer>
  );
};

export default NetworkHardendingSection;
