import { styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ContentContainer from "../../layout/ContentContainer";

const RDPSectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .para": {
    paddingTop: "25px",
  },

  "& .lastP": {
    paddingBottom: "25px",
  },
}));

const ResponsibleDisclosurePolicySection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line
  return (
    <ContentContainer>
      <RDPSectionWrapper>
        <Typography variant={"h5"} className={"para"}></Typography>
        <Typography variant={"h6"}>
          At SAFA Team, we may identify security vulnerabilities in third-party
          vendors' systems, applications, or services and wish to report them
          responsibly on behalf of security researchers. This Responsible
          Disclosure Policy outlines the guidelines and procedures for SAFA Team
          to report security vulnerabilities discovered by us to vendors or
          third parties.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Reporting a Vulnerability
        </Typography>
        <Typography variant={"h6"}>
          When reporting vulnerabilities we will contact the product owner or
          the affected third-party vendor or an associated security rewards
          program (hereinafter responsible party), whichever is identified to be
          responsible for coordinated disclosure. In our communication, we will
          provide the following information:
          <ul className={"list"}>
            <li className={"item"}>A summary of the vulnerability.</li>
            <li className={"item"}>
              Detailed steps to reproduce the vulnerability.
            </li>
            <li className={"item"}>
              Supporting evidence, such as screenshots, logs, or
              proof-of-concept code.
            </li>
          </ul>
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Response:
        </Typography>
        <Typography variant={"h6"}>
          We will monitor the vendor or third party's response and acknowledge
          any communication received from them. We will also maintain ongoing
          communication with the researcher(s) who identified the vulnerability.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Disclosure Timeline
        </Typography>
        <Typography variant={"h6"}>
          Our commitment to responsible disclosure extends to our reporting of
          vulnerabilities to third-party vendors and third parties. The
          disclosure timeline in these cases is as follows:
        </Typography>

        <Typography variant={"h6"}>
          Our policy is aligned with industry best practices and responsible
          disclosure guidelines. If a vendor does not respond to our disclosure
          within 120 days and after three follow-up attempts, we reserve the
          right to publish information about the vulnerability, with prior final
          notice to the vendor.
        </Typography>

        <Typography variant={"h6"}>
          If the vendor fails to respond within 30 days to initial communication
          after 3 repeated attempts we proceed with the disclosure.
        </Typography>

        <Typography variant={"h6"}>
          If the vendor declares the bug as a non-issue/no impact/won't
          fix/non-security bug we will proceed with the disclosure on our own
          discretion. If the vulnerability is publicly exploited or becomes
          public knowledge we might publish at our own discretion. This is
          especially important if someone publishes the same bug while we are in
          the disclosure process, in that case we don't want to wait another
          ~120 days.
        </Typography>

        <Typography variant={"h6"}>
          <b>Communication:</b> Throughout the disclosure process, we will
          maintain communication with the vendor and the security researcher(s)
          who identified the vulnerability, providing updates on the progress
          and resolution efforts.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Legal Protections
        </Typography>
        <Typography variant={"h6"}>
          Our policy is aligned with industry best practices and responsible
          disclosure guidelines. We will withhold public disclosure for 120 days
          after initial submission to leave sufficient time to distribute
          patches to the public. In special cases, upon the request of the
          responsible party accompanied by a reasonable explanation, we might
          extend this embargo period. Once the responsible party publishes the
          patch or vulnerability details we proceed with the disclosure
          regardless of the time frame. We will disclose vulnerability details
          at our own discretion, without further coordination in the following
          cases:
        </Typography>

        <Typography variant={"h6"}>
          <ul className={"list"}>
            <li className={"item"}>
              When the responsible party fails to respond to our initial
              submission within 14 days and three follow-up attempts
            </li>
            <li className={"item"}>
              When the responsible party chooses not to fix the vulnerability,
              unable to fix the vulnerability, declares it out of scope or
              without security impact
            </li>
            <li className={"item"}>
              When the vulnerability is actively exploited in the wild, or its
              details are published by other means
            </li>
          </ul>
        </Typography>

        <Typography variant={"h6"}>
          In any case we will inform the responsible party of the disclosure 48
          hours in advance.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Contact Information
        </Typography>
        <Typography variant={"h6"} className={"lastP"}>
          If you have any questions or concerns regarding SAFA Teams's
          Responsible Disclosure Policy for reporting vulnerabilities to
          third-party vendors or third parties, please contact us at
          hello@safateam.com.
        </Typography>
      </RDPSectionWrapper>
    </ContentContainer>
  );
};

export default ResponsibleDisclosurePolicySection;
