import { styled, Typography } from "@mui/material";
import ContentContainer from "../../layout/ContentContainer";

import React from "react";
import VulnerabilityResearcherComponent from "../../components/VulnerabilityResearcherComponent";
import { MOCKED_CONTENT, MOCKED_CONTENT_THREE } from "../../mocked_content";

const JobSectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .top": {
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",

    "& .tags": {
      alignItems: "center",
    },
  },

  "& .componentHolder": {
    display: "grid",
    gap: "33px",
    padding: "30px 0 50px 0",

    [theme.breakpoints.up("md")]: {
      padding: "110px 0 130px 0",
    },
  },
}));
const JobsSection = () => {
  return (
    <ContentContainer>
      <JobSectionWrapper>
        <div className={"top"}>
          <div className={"tags"}>
            <Typography variant={"body2"} color={"#58C9B3"}>
              TAGS | #Chrome
            </Typography>
          </div>
        </div>

        <div className={"componentHolder"}>
          <VulnerabilityResearcherComponent
            data={MOCKED_CONTENT}
            operatingSystem={"Chrome"}
          />
          <VulnerabilityResearcherComponent
            data={MOCKED_CONTENT_THREE}
            operatingSystem={"Android"}
          />
        </div>
      </JobSectionWrapper>
    </ContentContainer>
  );
};

export default JobsSection;
