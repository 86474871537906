import { Button, styled } from "@mui/material";
import React from "react";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "50px",
  height: "30px",
  color: "#58C9B3",
  borderColor: "#58C9B3",
  letterSpacing: "15.5%",
  fontSize: "16px",
  fontWeight: 400,

  "&:hover": {
    borderColor: "#58C9B3",
  },
}));

type Props = {
  text: string;
  onClick: () => void;
};

const RoundedButton: React.FC<Props> = ({ text, onClick }) => {
  return (
    <StyledButton variant={"outlined"} onClick={onClick}>
      {text}
    </StyledButton>
  );
};
export default RoundedButton;
