import { styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ContentContainer from "../../layout/ContentContainer";

const TermsSectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .para": {
    paddingTop: "25px",
  },

  "& .lastP": {
    paddingBottom: "25px",
  },

  "& ul": {
    fontFamily: "Rubik",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "150%",
  },
}));

const TermsSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line
  return (
    <ContentContainer>
      <TermsSectionWrapper>
        <Typography variant={"h5"} className={"para"}>
          Disclaimer
        </Typography>
        <Typography variant={"h6"}>
          The content offered here is for general information. We assume no
          guarantee/liability for the correctness, up-to-dateness, completeness
          and availability of the information/services provided. In particular,
          no legal claims arising from the use of the information and services
          accessed can be justified.
        </Typography>
        <Typography variant={"h6"}>
          References and links to other websites have been carefully selected.
          However, their content, correctness, topicality, completeness and
          availability is not within our sphere of influence and we assume no
          guarantee/liability for this.
        </Typography>
        <Typography variant={"h6"}>
          Furthermore, we assume no guarantee/liability for forms, in particular
          not for their up-to-dateness, suitability for a specific purpose and
          freedom from viruses.
        </Typography>
        <Typography variant={"h6"}>
          We reserve the right to change, supplement or delete the
          information/services offered here at any time and without prior notice
          or to completely or temporarily discontinue the website.
        </Typography>
        <Typography variant={"h6"}>
          We try to minimize problems when accessing the information/services
          due to disruptions or incompatibilities. However, it cannot be ruled
          out that data and information on our website will not be displayed or
          will be displayed incorrectly. We assume no liability that our service
          will not be interrupted or otherwise affected by disruptions.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Intellectual Property
        </Typography>
        <Typography variant={"h6"}>
          All content, the design/layout, any sound and video sequences are
          protected by copyright. All rights to it are reserved by the rights
          holder. Changes may not be made.
        </Typography>
        <Typography variant={"h6"}>
          Public use of the information/services may only take place with our
          consent and by naming and making visible the source. Anyone is free to
          just link to our content with a simultaneous reference to the source
          and does not require any special approval.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Final Provisions
        </Typography>
        <Typography variant={"h6"}>
          Austrian law applies exclusively to the use of the
          information/services.
        </Typography>
        <Typography variant={"h6"}>
          If parts or individual formulations of this text do not, no longer or
          not completely correspond to the applicable legal situation, the
          remaining parts remain unaffected in their content and validity.
        </Typography>
        <Typography variant={"h6"}>
          Instead of an invalid provision or to fill a gap, an appropriate,
          legally permissible provision will be applied that comes closest to
          what would have been intended in terms of the meaning and purpose of
          these terms of use if the partial invalidity or gap was known.
        </Typography>
        <Typography variant={"h5"} className={"para"}>
          Photo Credit
        </Typography>
        <Typography variant={"h6"} className={"lastP"}>
          <ul className={"list"}>
            <li className={"item"}>Dcstudio - Freepik.com</li>
            <li className={"item"}>Pressfoto - Freepik.com</li>
            <li className={"item"}>Natanaelginting - Freepik.com</li>
          </ul>
        </Typography>
      </TermsSectionWrapper>
    </ContentContainer>
  );
};

export default TermsSection;
