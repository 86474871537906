export const MOCKED_CONTENT = {
  description: {
    title: "Description",
    content:
      "This position would be responsible for discovering and exploiting vulnerabilities affecting browsers and web app. We are looking for who are proficient in Web vulnerability finding or exploit development,familiar with the processes, methods and utilization of vulnerabilities.",
  },
  we_offer: {
    title: "",
    content: [],
  },
  required_skills: {
    title: "Required Skills",
    content: [
      "Understanding of the internals of modern web browsers and mobile apps.",
      "Knowledge of software security",
      "Reverse engineering (IDA, GDB, Ghidra, …)",
      "Experience in vulnerability finding or exploit development",
      "Programming skills (C, Javascript, python, assembly …)",
      "Software fuzzing",
      "Dynamic analysis and debugging",
    ],
  },
  preferred_skills: {
    title: "Preferred Skills",
    content: [
      "Familiar with ARM and AArch64",
      "Familiar with Web/Binary vulnerability finding or exploit development",
    ],
  },
};

export const MOCKED_CONTENT_TWO = {
  description: {
    title: "Description",
    content:
      "This position would be responsible for discovering and exploiting vulnerabilities affecting on Apple devices running iOS and iOS-based operating systems. We are looking for who are proficient in iOS/macOS vulnerability finding or exploit development,familiar with the processes, methods and utilization of vulnerabilities.",
  },
  we_offer: {
    title: "",
    content: [],
  },
  required_skills: {
    title: "Required Skills",
    content: [
      "Vulnerability research experience with mobile platforms and operating systems, preferably in iOS.",
      "Knowledge of macOS and iOS architecture.",
      "Reverse engineering (IDA, GDB, Ghidra, …)",
      "Experience in vulnerability finding or exploit development",
      "Programming skills (C, python, ARM/AArch64 assembly …)",
      "Software fuzzing",
      "Dynamic analysis and debugging",
    ],
  },
  preferred_skills: {
    title: "Preferred Skills",
    content: [
      "Familiar with ARM and AArch64",
      "Knowledge of privilege escalation exploit(ex, jailbreaking) on Apple devices running iOS and iOS-based operating systems.",
    ],
  },
};

export const MOCKED_CONTENT_THREE = {
  description: {
    title: "Description",
    content:
      "The Android vulnerability researcher will be responsible for discovering, evaluating and exploiting vulnerabilities in the operating system of Android devices. We are looking for candidates who are proficient in Android/Linux vulnerability finding, exploit development, familiar with modern mitigations and their bypasses. We are looking to fill multiple positions in this role with varying seniority level and experience requirements.",
  },
  we_offer: {
    title: "We Offer",
    content: [
      "Full-remote work with flexible working hours and/or great office locations in Vienna and Barcelona",
      "An opportunity to work as part of a small but experienced team in a researcher focused company",
      "Learning and conference compensation",
      "Competitive salary and bonus scheme",
    ],
  },

  required_skills: {
    title: "Requirements",
    content: [
      "Vulnerability research experience with mobile platforms and operating systems, preferably Android (iOS and Linux experience is considered)",
      "Deep understanding of Android/Linux OS internals, interfaces and security systems",
      "Experience with source code auditing of large C, C++ code bases",
      "Experience in vulnerability finding and exploit development in operating systems and native applications",
      "Programming skills (C, python, ARM/AArch64 assembly …)",
    ],
  },
  preferred_skills: {
    title: "Preferred Skills",
    content: [
      "Demonstrated experience in identifying and exploiting vulnerabilities in high security software systems",
      "Experience in development of robust, reliable exploits",
      "Deep understanding of the memory management systems of Android mobile devices",
      "Familiarity with modern mitigation techniques (Selinux, hypervisors, trusted computing, CFI, stack cookies, KASLR, heap protections)",
      "Binary auditing, debugging and instrumentation (GDB, Frida, IDA, Ghidra)",
      "Fuzzing OS internals and APIs (syzkaller, AFL++, libfuzzer)",
    ],
  },
};
