import { Box, Grid, styled, Typography } from "@mui/material";
import RoundedButton from "../shared/RoundedButton";
import React, { useState } from "react";

const GridWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "24px",
  backgroundColor: "#F5F5F5",
  boxSizing: "border-box",

  [theme.breakpoints.up("md")]: {
    height: "100px",
  },

  "&:hover": {
    border: "1px solid #58C9B3",
  },

  "& .MuiGrid-root": {
    paddingTop: "25px",
    marginLeft: 0,
  },

  "& .MuiGrid-item": {
    height: "52px",
    padding: "0 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    color: "#48474C",

    [theme.breakpoints.up("md")]: {
      borderRight: "1px solid #D9D9D9",
    },

    "&:last-of-type": {
      borderRight: "none",
      padding: 0,
    },
    "& .MuiTypography-h3": {
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px",
      },
    },
  },

  "& .MuiBox-root": {
    "& .MuiTypography-h3": {
      padding: "0 15px",
    },
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  marginTop: "20px",
  padding: "20px 40px",
  borderRadius: "24px",
  backgroundColor: "#F5F5F5",
  border: "1px solid #58C9B3",

  "& .MuiTypography-h3": {
    padding: "24px 0 12px 0",
  },

  "& .contentList": {
    paddingLeft: 20,

    "li::marker": {
      color: "#58C9B3",
    },
  },
}));

type Props = {
  data: {
    description: {
      title: string;
      content: string;
    };
    we_offer: {
      title: string;
      content: string[];
    };
    required_skills: {
      title: string;
      content: string[];
    };
    preferred_skills: {
      title: string;
      content: string[];
    };
  };
  operatingSystem: string;
};
const VulnerabilityResearcherComponent: React.FC<Props> = ({
  data,
  operatingSystem,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {data && (
        <>
          <GridWrapper>
            <Grid container spacing={3} columns={14} alignItems={"center"}>
              <Grid item xs={15} md={4}>
                <Typography color={"primary"} variant={"h3"}>
                  Vulnerability Researcher
                </Typography>
              </Grid>
              <Grid item xs={15} md={2}>
                <Typography variant={"body2"}>#{operatingSystem}</Typography>
              </Grid>
              <Grid item xs={15} md={3}>
                <Typography variant={"body2"}>
                  Full-Time Flexible/Remote
                </Typography>
              </Grid>
              <Grid item xs={15} md={2}>
                <Typography variant={"body2"}>Remote</Typography>
              </Grid>
              <Grid item xs={15} md={3}>
                <Typography>
                  <RoundedButton
                    text={showMore ? "SHOW LESS" : "SEE MORE"}
                    onClick={() => setShowMore((prevState) => !prevState)}
                  />
                </Typography>
              </Grid>
            </Grid>
          </GridWrapper>
          {showMore && (
            <ContentWrapper>
              <Typography variant={"h3"} color={"primary"}>
                {data.description.title}
              </Typography>
              <Typography variant={"h6"} color={"#48474C"}>
                {data.description.content}
              </Typography>

              {data.we_offer.title !== "" ? (
                <Typography variant={"h3"} color={"primary"}>
                  {data.we_offer.title}
                </Typography>
              ) : null}

              {data.we_offer.content.length > 0 && (
                <ul className={"contentList"}>
                  {data.we_offer.content.map((el, i) => {
                    return (
                      <li key={i}>
                        <Typography variant={"h6"} color={"#48474C"}>
                          {el}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              )}

              <Typography variant={"h3"} color={"primary"}>
                {data.required_skills.title}
              </Typography>
              <ul className={"contentList"}>
                {data.required_skills.content.map((el, i) => {
                  return (
                    <li key={i}>
                      <Typography variant={"h6"} color={"#48474C"}>
                        {el}
                      </Typography>
                    </li>
                  );
                })}
              </ul>

              <Typography variant={"h3"} color={"primary"}>
                {data.preferred_skills.title}
              </Typography>
              <ul className={"contentList"}>
                {data.preferred_skills.content.map((el, i) => {
                  return (
                    <li key={i}>
                      <Typography variant={"h6"} color={"#48474C"}>
                        {el}
                      </Typography>
                    </li>
                  );
                })}
              </ul>

              <Typography variant={"h6"} color={"primary"} sx={{ mt: 4 }}>
                If you're interested in this position you may apply by sharing
                your resume with us via email at &nbsp;
                <a
                  href={`mailto:hello@safateam.com?subject=Job application for ${operatingSystem} Vulnerability Researcher`}
                  style={{ textDecoration: "underline", color: "#48474C" }}
                >
                  hello@safateam.com
                </a>
              </Typography>
            </ContentWrapper>
          )}
        </>
      )}
    </>
  );
};
export default VulnerabilityResearcherComponent;
