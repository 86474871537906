import React, { useState } from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ContentContainer from "../layout/ContentContainer";
import TriangleFullHero from "../assets/TriangesOurServices";
import ActionButton from "../shared/ActionButton";
import { useNavigate } from "react-router-dom";

const SectionHolder = styled("div")(({ theme }) => ({
  marginBottom: "200px",
  width: "100%",
  height: "300px",
  paddingTop: "100px",
  paddingBottom: "50px",
  display: "flex",
  flexDirection: "column",

  "& .up": {
    width: "100%",
  },
  "& .down": {
    width: "100%",
    display: "flex",
    marginTop: "60px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginTop: "40px",
    },
  },
  "& .leftSecure": {
    width: "50%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .row": {
    listStyle: "none",
    textDecoration: "none",
    paddingInlineStart: "0",
    marginBlockStart: "0",
    marginBlockEnd: "0",
  },
  "& .item": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingBottom: 22,

    "& .MuiTypography-subtitle2": {
      lineHeight: "unset",
    },

    "& .arrow": {
      rotate: "0deg",
      color: theme.palette.primary.light,
    },

    "& svg": {
      color: "#D9D9D9",
      rotate: "-90deg",
      marginRight: "10px",
      width: "30px",
      height: "30px",
    },
  },
  "& .rightSecure": {
    background: theme.palette.primary.light,
    width: "50%",
    height: "370px",
    display: "flex",
    //padding: "80px 0 100px 0",
    justifyContent: "center",
    alignItems: "center",
    WebkitClipPath: "polygon(50px 0%, 100% 0%, 100% 100%, 0 100%, 0 50px)",
    clipPath: "polygon(50px 0%, 100% 0%, 100% 100%, 0 100%, 0 50px)",

    [theme.breakpoints.down("md")]: {
      margin: "auto",
      width: "80%",
      marginTop: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "30px",
    },
  },
  "& .textHolder": {
    width: "75%",
  },
}));

const BgHolder = styled("div")(({ theme }) => ({
  width: "550px",
  height: "500px",
  position: "absolute",
  right: 0,
  marginTop: "250px",
  overflowY: "visible",
  [theme.breakpoints.down("sm")]: {
    width: "300px",
    height: "280px",
    marginTop: "550px",
  },
  "& .MuiSvgIcon-root": {
    width: "100%",
    height: "100%",
  },
}));

const OUR_SERVICES_MOCK = [
  {
    service: "Security Assessment",
    key: "security_assessment",
    text: "In depth source code review and binary analysis for proprietary software. Consulting service through entire software development lifecycle - from secure design phase through review and assistance in exploit mitigation",
    url: "/security-assessment",
  },
  {
    service: "Training",
    key: "training",
    text: "Specialized training for development teams focused on improving their secure development practices from better understanding modern techniques to countermeasure design.",
    url: "/training",
  },
  {
    service: "Network Hardening Consulting",
    key: "network_hardening_consulting",
    text: "Complete threat assessment for network, proprietary software and web applications. Leveraging experience with latest developments in the cyber arena to aid in securing networks.",
    url: "/network-hardening-consulting",
  },
  {
    service: "Reverse Engineering and Vulnerability research",
    key: "reverse_engineering",
    text: "Deep analysis of products focused on identifying product vulnerabilities using state-of-the-art research techniques to generate value for customers.",
    url: "/reverse-engineering-and-vulnerability-research",
  },
  {
    service: "Threat Intelligence",
    key: "threat_intelligence",
    text: "Our threat intelligence analysts have been dedicated to malware research and APT investigation for more than 10 years. With language and cultural advantages, we possess specific expertise in cyber espionage in the Asia-Pacific region.",
    url: "/threat-intelligence",
  },
];

const OurServicesSection = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("security_assessment");

  return (
    <ContentContainer>
      <BgHolder>
        <TriangleFullHero />
      </BgHolder>
      <SectionHolder id="services">
        <Box className={"up"}>
          <Typography variant={"h2"}>Our Services</Typography>
        </Box>
        <div className={"down"}>
          <div className={"leftSecure"}>
            <ul className={"row"}>
              {OUR_SERVICES_MOCK.map((el) => {
                return (
                  <li
                    className={"item"}
                    onClick={() => setActive(el.key)}
                    key={el.key}
                  >
                    <PlayArrowIcon
                      className={active === el.key ? "arrow" : ""}
                    />
                    <Typography
                      variant={"subtitle2"}
                      sx={
                        active === el.key
                          ? { color: "#58C9B3" }
                          : { fontWeight: "400" }
                      }
                    >
                      {el.service}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={"rightSecure"}>
            <div className={"textHolder"}>
              <Typography
                variant={"subtitle2"}
                sx={{ color: "white", lineHeight: "unset", pb: "20px" }}
              >
                {OUR_SERVICES_MOCK.find((el) => el.key === active)?.service}
              </Typography>
              <Typography variant={"body2"} sx={{ lineHeight: "219%" }}>
                {OUR_SERVICES_MOCK.find((el) => el.key === active)?.text}
              </Typography>
              <ActionButton
                sx={{ marginTop: "20px" }}
                text={"See more"}
                color={"#58C9B3"}
                onClick={() =>
                  navigate(
                    `our-services${
                      OUR_SERVICES_MOCK.find((el) => el.key === active)?.url
                    }`
                  )
                }
              />
            </div>
          </div>
        </div>
      </SectionHolder>
    </ContentContainer>
  );
};
export default OurServicesSection;
