import { Box, Container, styled, Typography } from "@mui/material";
import TriangleHero from "../assets/TriangleHero";

const Hero = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  backgroundImage: `url(${
    process.env.PUBLIC_URL + "/images/hero_image_safa.png"
  })`,
  height: "860px",
  width: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  overflow: "hidden",
  WebkitClipPath: "polygon(0 0, 0 100%, 0% 100%, 100% 88%, 100% 0)",
  clipPath: "polygon(0 0, 0 100%, 0% 100%, 100% 88%, 100% 0)",

  "& .contentWrapper": {
    margin: "auto 0",
    maxWidth: "700px",
  },
  "& .MuiTypography-h1": {
    "& > span": {
      color: theme.palette.primary.light,
    },
  },
}));

const Triangle = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: -20,

  "& .MuiSvgIcon-root": {
    width: 380,
    height: "100%",
  },
}));

const HeroSection = () => {
  return (
    <Hero>
      <Container maxWidth="xl" sx={{ margin: "auto" }}>
        <Box className={"contentWrapper"}>
          <Typography variant={"h1"}>
            <span>SAFA</span> is a cybersecurity company & research lab,
            leveraging our expertise to provide customers with a variety of
            high-end consulting services.
          </Typography>
        </Box>
      </Container>
      <Triangle>
        <TriangleHero />
      </Triangle>
    </Hero>
  );
};
export default HeroSection;
