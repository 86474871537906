import HeroSection from "../shared/HeroSection";
import JobsSection from "../sections/career/JobsSection";
import { styled } from "@mui/material";
import JoinUsSection from "../sections/JoinUsSection";
import TeamSection from "../sections/TeamSection";
import ContacUsSection from "../sections/ContacUsSection";

const CareerWrapper = styled("div")(({ theme }) => ({
  width: "100%",
}));

const Career = () => {
  return (
    <CareerWrapper>
      <HeroSection headline={"Open positions"} />
      <JobsSection />
      <JoinUsSection isCareerPage={true} />
      <TeamSection />
      <ContacUsSection />
    </CareerWrapper>
  );
};

export default Career;
