import { styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ContentContainer from "../../layout/ContentContainer";
import ActionButton from "../../shared/ActionButton";

const SecuritySectionWrapper = styled("div")(({ theme }) => ({
  width: "100%",

  "& .txtHolder": {
    paddingTop: "25px",
    paddingBottom: "25px",
  },

  "& .lastP": {
    paddingBottom: "25px",
  },

  "& ul": {
    fontFamily: "Rubik",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "150%",
  },
}));

const SecurityAssessmentSection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []); // eslint-disable-line
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
  return (
    <ContentContainer>
      <SecuritySectionWrapper>
        <div className={"txtHolder"}>
          <Typography variant={"h6"}>
            At SAFA, we understand that protecting your proprietary software and
            ensuring its resilience against potential cyber threats is of
            paramount importance. That's why we offer our comprehensive Security
            Assessment service, designed to provide you with a meticulous
            analysis of your source code and binaries, along with expert
            consulting throughout the entire software development lifecycle.
          </Typography>
          <br />
          <Typography variant={"h6"}>
            Our Security Assessment service combines advanced techniques,
            state-of-the-art tools, and the expertise of our highly skilled
            security professionals to identify vulnerabilities, uncover
            potential weaknesses, and offer actionable recommendations to
            enhance the security posture of your software. With a focus on
            secure design, thorough source code review, and binary analysis, we
            leave no stone unturned in our quest to fortify your software
            against cyber threats. This includes:
          </Typography>

          <ul className={"list"}>
            <li className={"item"}>In-Depth Source Code Review</li>
            <li className={"item"}>Binary Analysis</li>
            <li className={"item"}>Secure Design</li>
            <li className={"item"}>
              Review and Assistance in Exploit Mitigation
            </li>
          </ul>
          <Typography variant={"h6"}>
            By leveraging our Security Assessment service, you can benefit from:
          </Typography>

          <ul className={"list"}>
            <li className={"item"}>
              Enhanced Security: Our in-depth analysis and expert consulting
              help identify vulnerabilities and weaknesses in your software,
              allowing you to implement effective security measures and fortify
              your defenses against potential threats.
            </li>
            <li className={"item"}>
              Improved Software Quality: Our source code review and binary
              analysis not only focus on security but also help uncover coding
              errors, performance issues, and other software defects, leading to
              an overall improvement in software quality and reliability.
            </li>
            <li className={"item"}>
              Cost Savings: Detecting and addressing security vulnerabilities
              early in the development lifecycle is more cost-effective than
              fixing them after a breach or a security incident. Our service
              helps you mitigate risks and avoid costly remediation efforts.
            </li>
            <li className={"item"}>
              Expert Guidance: With our team of experienced security
              professionals at your side, you gain access to expert guidance and
              support throughout the software development process. We provide
              practical recommendations, help you make informed security
              decisions.
            </li>
          </ul>
          <ActionButton
            text={"Inquiry"}
            color={"#58C9B3"}
            onClick={() =>
              window.scrollTo({
                left: 0,
                top: isDesktop ? 1000 : 2000,
                behavior: "smooth",
              })
            }
          />
        </div>
      </SecuritySectionWrapper>
    </ContentContainer>
  );
};

export default SecurityAssessmentSection;
