import {
  AppBar,
  Box,
  Container,
  Link,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SafaLogo from "../assets/SafaLogo";
import LinkedInIcon from "../assets/LinkedInIcon";
import { useEffect, useState } from "react";
import MobileNavigation from "./MobileNavigation";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import TwitterIcon from "../assets/TwitterIcon";

export const NavigationBar = styled(AppBar)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  boxShadow: "none",

  [theme.breakpoints.up("md")]: {
    background: "transparent",
  },

  "& .MuiToolbar-root": {
    height: "80px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 10,
  },
  "& .MuiLink-root": {
    "& .MuiSvgIcon-root": {
      width: "150px",
      height: "100%",
      cursor: "pointer",
    },
  },
  "& .socialIcons": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "20px",

    "& .MuiSvgIcon-root": {
      cursor: "pointer",
    },
  },
  "& .menuButton": {
    color: theme.palette.background.paper,
    fontSize: "30px",
  },
}));

export const NavLinks = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  columnGap: "30px",
  position: "relative",
  margin: "auto",

  [theme.breakpoints.up("md")]: {
    columnGap: "60px",
  },

  "& .MuiTypography-root": {
    cursor: "pointer",
    whiteSpace: "nowrap",
    color: theme.palette.background.paper,
  },
}));

export const navLinks = [
  { name: "HOME", url: "/" },
  { name: "SERVICES", url: "services" },
  { name: "BLOG", url: "blog" },
  // { name: "FAQ", url: "faq" },
  { name: "CONTACT", url: "contact" },
];

const Navigation = () => {
  const navigate = useNavigate();
  const { pathname, ...location } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [url, setUrl] = useState("/");
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
  const handleMenu = () => setMenuOpen((prevState) => !prevState);

  useEffect(() => {
    if (url !== "/") {
      if (url === "contact") {
        window.scrollTo({
          top: isDesktop ? 3500 : 4700,
          left: 0,
          behavior: "smooth",
        });
      }
      if (url === "blog") {
        window.scrollTo({
          top: isDesktop ? 2400 : 3000,
          left: 0,
          behavior: "smooth",
        });
      }
      if (url === "services") {
        window.scrollTo({
          top: isDesktop ? 1650 : 2000,
          left: 0,
          behavior: "smooth",
        });
      }
    }
    return () => setUrl("/");
  }, [url, location.key]); // eslint-disable-line

  return (
    <NavigationBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link onClick={() => navigate("/")}>
            <SafaLogo />
          </Link>
          {isDesktop ? (
            <>
              <NavLinks>
                {navLinks.map((link, i) => {
                  return (
                    <Link
                      onClick={() => {
                        if (pathname === "/career" || "/data-protection") {
                          navigate("/");
                        }
                        setUrl(link.url);
                      }}
                      key={link.name + i}
                    >
                      <Typography variant={"body2"}>{link.name}</Typography>
                    </Link>
                  );
                })}
                <Typography
                  variant={"body2"}
                  onClick={() => {
                    navigate("/career");
                    setUrl("career");
                  }}
                >
                  CAREER
                </Typography>
              </NavLinks>
              <Box className={"socialIcons"}>
                <a
                  href={
                    "https://www.linkedin.com/company/safa-team/?viewAsMember=true"
                  }
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>

                <a
                  href={"https://twitter.com/SAFATeamGmbH"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
              </Box>
            </>
          ) : (
            <>
              {menuOpen ? (
                <CloseIcon
                  className={"menuButton"}
                  onClick={handleMenu}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <MenuIcon
                  className={"menuButton"}
                  onClick={handleMenu}
                  sx={{ cursor: "pointer" }}
                />
              )}
            </>
          )}
        </Toolbar>
        {!isDesktop && menuOpen && (
          <MobileNavigation
            setUrl={setUrl}
            navLinks={navLinks}
            setMenuOpen={setMenuOpen}
            pathname={pathname}
          />
        )}
      </Container>
    </NavigationBar>
  );
};
export default Navigation;
