import { SvgIcon } from "@mui/material";
const TriangleFullHero = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="527"
      height="513"
      viewBox="0 0 527 513"
      fill="none"
    >
      <path
        d="M333.29 74.5443L285.871 2.45771e-05L238.452 74.5443L333.29 74.5443ZM326.516 90.1308L247.258 90.1308L286.548 157.898L326.516 90.1308ZM392.903 186.361L353.613 119.948L314.323 186.361L392.903 186.361ZM249.968 186.361L210.677 119.948L171.387 186.361L249.968 186.361ZM383.419 225.666L321.774 225.666L352.935 279.202L383.419 225.666ZM243.871 225.666L182.226 225.666L213.387 279.202L243.871 225.666ZM453.194 300.888L421.355 247.351L389.516 300.888L453.194 300.888ZM317.032 300.888L285.194 247.351L253.355 300.888L317.032 300.888ZM174.097 300.888L142.258 247.351L110.419 300.888L174.097 300.888ZM443.71 335.449L396.29 335.449L420 376.787L443.71 335.449ZM310.258 335.449L262.839 335.449L286.548 376.787L310.258 335.449ZM168 335.449L120.581 335.449L144.29 376.787L168 335.449ZM506.71 408.638L486.387 370.688L466.065 408.638L506.71 408.638ZM373.935 408.638L353.613 370.688L333.29 408.638L373.935 408.638ZM234.387 408.638L214.065 370.688L193.742 408.638L234.387 408.638ZM101.613 408.638L81.2903 370.688L60.9677 408.638L101.613 408.638ZM504 456.075L469.452 456.075L487.065 487.926L504 456.075ZM370.548 456.075L336 456.075L353.613 487.926L370.548 456.075ZM231 456.075L196.452 456.075L214.065 487.926L231 456.075ZM98.2258 456.075L63.6774 456.075L81.2903 487.926L98.2258 456.075ZM567 513L552.774 487.926L538.548 513L567 513ZM434.226 513L420 487.926L405.774 513L434.226 513ZM300.774 513L286.548 487.926L272.323 513L300.774 513ZM158.516 513L144.29 487.926L130.065 513L158.516 513ZM28.4516 513L14.2258 487.926L0 513L28.4516 513Z"
        fill="url(#paint0_linear_23_26)"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_23_26"
          x1="143.251"
          y1="553.587"
          x2="470.8"
          y2="210.355"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="4.92219e-07" stopColor="#58C9B3" />
          <stop offset="1" stopColor="#58C9B3" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
export default TriangleFullHero;
