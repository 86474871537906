import { SvgIcon } from "@mui/material";

const LinkedInIcon = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        d="M16 32.2362C7.16418 32.2362 0 25.072 0 16.2362C0 7.40034 7.16418 0.23616 16 0.23616C24.8358 0.23616 32 7.40034 32 16.2362C32 25.072 24.8358 32.2362 16 32.2362ZM16 1.43019C7.8806 1.43019 1.19403 8.11676 1.19403 16.2362C1.19403 24.3556 7.8806 31.0421 16 31.0421C24.1194 31.0421 30.806 24.3556 30.806 16.2362C30.806 8.11676 24.1194 1.43019 16 1.43019Z"
        fill="white"
      />
      <path
        d="M9.55228 12.6541H12.418V22.2063H9.55228V12.6541ZM10.9851 7.87794C11.9403 7.87794 12.6568 8.59436 12.6568 9.54959C12.6568 10.5048 11.9403 11.2212 10.9851 11.2212C10.0299 11.2212 9.31348 10.5048 9.31348 9.54959C9.31348 8.59436 10.0299 7.87794 10.9851 7.87794Z"
        fill="white"
      />
      <path
        d="M14.3282 12.6541H17.1939V13.8481C17.6715 13.1317 18.6268 12.4153 20.0596 12.4153C23.1641 12.4153 23.6417 14.3257 23.6417 16.9526V22.2063H20.776V17.4302C20.776 16.2362 20.776 14.8033 19.3432 14.8033C17.9103 14.8033 17.6715 15.9974 17.6715 17.1914V21.9675H14.3282V12.6541Z"
        fill="white"
      />
    </SvgIcon>
  );
};
export default LinkedInIcon;
