import { SvgIcon } from "@mui/material";
const TriangleContactUs = () => {
  return (
    <SvgIcon
      width="536"
      height="513"
      viewBox="0 0 536 513"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M302.29 74.5443L254.871 2.45771e-05L207.452 74.5443L302.29 74.5443ZM295.516 90.1308L216.258 90.1308L255.548 157.898L295.516 90.1308ZM361.903 186.361L322.613 119.948L283.323 186.361L361.903 186.361ZM218.968 186.361L179.677 119.948L140.387 186.361L218.968 186.361ZM352.419 225.666L290.774 225.666L321.936 279.202L352.419 225.666ZM212.871 225.666L151.226 225.666L182.387 279.202L212.871 225.666ZM422.194 300.888L390.355 247.351L358.516 300.888L422.194 300.888ZM286.032 300.888L254.194 247.351L222.355 300.888L286.032 300.888ZM143.097 300.888L111.258 247.351L79.4194 300.888L143.097 300.888ZM412.71 335.449L365.29 335.449L389 376.787L412.71 335.449ZM279.258 335.449L231.839 335.449L255.548 376.787L279.258 335.449ZM137 335.449L89.5807 335.449L113.29 376.787L137 335.449ZM475.71 408.638L455.387 370.688L435.065 408.638L475.71 408.638ZM342.936 408.638L322.613 370.688L302.29 408.638L342.936 408.638ZM203.387 408.638L183.065 370.688L162.742 408.638L203.387 408.638ZM70.613 408.638L50.2904 370.688L29.9678 408.638L70.613 408.638ZM473 456.075L438.452 456.075L456.065 487.926L473 456.075ZM339.548 456.075L305 456.075L322.613 487.926L339.548 456.075ZM200 456.075L165.452 456.075L183.065 487.926L200 456.075ZM67.2259 456.075L32.6774 456.075L50.2904 487.926L67.2259 456.075ZM536 513L521.774 487.926L507.548 513L536 513ZM403.226 513L389 487.926L374.774 513L403.226 513ZM269.774 513L255.548 487.926L241.323 513L269.774 513ZM127.516 513L113.29 487.926L99.0646 513L127.516 513ZM-2.54834 513L-16.7742 487.926L-30.9999 513L-2.54834 513Z"
        fill="url(#paint0_linear_52_153)"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_52_153"
          x1="112.251"
          y1="553.587"
          x2="439.8"
          y2="210.355"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="4.92219e-07" stopColor="white" />
          <stop offset="1" stopColor="#58C9B3" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
export default TriangleContactUs;
