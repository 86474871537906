import React from "react";

const MapComponent = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "inherit",
        overflow: "hidden",
        position: "relative",
      }}
      dangerouslySetInnerHTML={{
        __html: `
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2658.7417306496027!2d16.3623516!3d48.211590400000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d07962ac2852d%3A0x2318b275a82cc7e1!2sTeinfaltstra%C3%9Fe%208%2F8a%2C%201010%20Wien%2C%20Austria!5e0!3m2!1sen!2srs!4v1686920273069!5m2!1sen!2srs" 
          style="border:0; position: relative; top: 0; left: 0; width: 100%; height: 100%;"
          allowfullscreen="" 
          loading="lazy">
        </iframe>
        `,
      }}
    />
  );
};

export default MapComponent;
