import { SvgIcon } from "@mui/material";

const TriangleHero = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="364"
      height="451"
      viewBox="0 0 364 451"
      fill="none"
    >
      <path
        d="M130.29 74.5443L82.871 2.45771e-05L35.4516 74.5443L130.29 74.5443ZM123.516 90.1308L44.2581 90.1308L83.5484 157.898L123.516 90.1308ZM189.903 186.361L150.613 119.948L111.323 186.361L189.903 186.361ZM46.9678 186.361L7.67746 119.948L-31.6129 186.361L46.9678 186.361ZM180.419 225.666L118.774 225.666L149.936 279.202L180.419 225.666ZM40.871 225.666L-20.7742 225.666L10.3871 279.202L40.871 225.666ZM250.194 300.888L218.355 247.351L186.516 300.888L250.194 300.888ZM114.032 300.888L82.1936 247.351L50.3549 300.888L114.032 300.888ZM-28.9032 300.888L-60.7419 247.351L-92.5806 300.888L-28.9032 300.888ZM240.71 335.449L193.29 335.449L217 376.787L240.71 335.449ZM107.258 335.449L59.8387 335.449L83.5484 376.787L107.258 335.449ZM-35 335.449L-82.4193 335.449L-58.7096 376.787L-35 335.449ZM303.71 408.638L283.387 370.688L263.065 408.638L303.71 408.638ZM170.936 408.638L150.613 370.688L130.29 408.638L170.936 408.638ZM31.3871 408.638L11.0646 370.688L-9.258 408.638L31.3871 408.638ZM-101.387 408.638L-121.71 370.688L-142.032 408.638L-101.387 408.638ZM301 456.075L266.452 456.075L284.065 487.926L301 456.075ZM167.548 456.075L133 456.075L150.613 487.926L167.548 456.075ZM28.0001 456.075L-6.54834 456.075L11.0646 487.926L28.0001 456.075ZM-104.774 456.075L-139.323 456.075L-121.71 487.926L-104.774 456.075ZM364 513L349.774 487.926L335.548 513L364 513ZM231.226 513L217 487.926L202.774 513L231.226 513ZM97.7743 513L83.5484 487.926L69.3226 513L97.7743 513ZM-44.4838 513L-58.7096 487.926L-72.9354 513L-44.4838 513ZM-174.548 513L-188.774 487.926L-203 513L-174.548 513Z"
        fill="url(#paint0_linear_5_88)"
        fillOpacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_88"
          x1="-59.7488"
          y1="553.587"
          x2="267.8"
          y2="210.355"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="4.92219e-07" stopColor="#58C9B3" />
          <stop offset="1" stopColor="#58C9B3" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default TriangleHero;
