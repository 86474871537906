import { Box, Link, styled, Typography } from "@mui/material";
import React from "react";

const CardWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "& .image": {
    maxWidth: "500px",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "344px",
    },
    "&:hover": {
      transform: "scale(1.05)",
      transition: "all 0.5s",
    },
  },
  "& .imageWrapper": {
    overflow: "hidden",
  },
}));

type Props = {
  title: string;
  imageUrl: string;
  publishedDate: string;
  blogUrl: string;
};

const ImageCard: React.FC<Props> = ({
  imageUrl,
  title,
  publishedDate,
  blogUrl,
}) => {
  return (
    <CardWrapper>
      <div className={"imageWrapper"}>
        <img className={"image"} src={imageUrl} alt={title} />
      </div>
      <Typography
        variant={"subtitle1"}
        sx={{
          pt: 0.5,
          textAlign: {
            xs: "center",
            md: "left",
          },
          width: "100%",
        }}
      >
        {publishedDate}
      </Typography>
      <Typography variant={"h3"} sx={{ pt: 2 }}>
        <Link
          underline="hover"
          href={blogUrl}
          target={"_blank"}
          rel="noreferrer"
          color={"background.paper"}
          sx={{ cursor: "pointer" }}
        >
          {title}
        </Link>
      </Typography>
    </CardWrapper>
  );
};
export default ImageCard;
