import { Container, useMediaQuery, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";

type Props = {
  children: React.ReactNode;
  style?: CSSProperties;
};
const ContentContainer = ({ children, style }: Props) => {
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));
  return (
    <Container disableGutters={!!isDesktop} maxWidth={"lg"} style={style}>
      {children}
    </Container>
  );
};
export default ContentContainer;
